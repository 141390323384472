'use client';

import { withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { JSXElementConstructor } from 'react';

import { makeLDContext } from '../launch-darkly/launch-darkly-context';

function LaunchDarklyProvider({ children }: React.PropsWithChildren) {
  return <>{children}</>;
}

export default withLDProvider(makeLDContext())(LaunchDarklyProvider) as JSXElementConstructor<React.PropsWithChildren>;
