const btnBaseStyles = 'rounded-lg w-full py-3 px-8 text-center font-bold';
const baseInputErrorStyles = 'pr-10 ring-red-400 placeholder:text-red-400 focus:ring-red-500';
const baseInputStyles =
  'block w-full rounded-lg border-0 py-1.5 md:text-base sm:text-sm sm:leading-6 focus:ring-2 focus:ring-inset ring-1 ring-inset';

export const styles = {
  inputError: `${baseInputStyles} ${baseInputErrorStyles} text-red-900`,
  inputClean: `${baseInputStyles} text-gray-900 shadow-sm ring-gray-400 placeholder:text-gray-600 focus:ring-blue-800`,
  dropdownError: `${baseInputStyles} ${baseInputErrorStyles} text-red-400`,
  btnDisabled: 'text-gray-500 bg-gray-200',
  btnEnabled: 'bg-blue-900 text-white',
  btnBase: btnBaseStyles,
  btnLink: `${btnBaseStyles} text-blue-900 cursor-pointer`,
  inlineLink: 'font-bold text-blue-900',
};
