'use client';

import { ErrorBoundary, Provider } from '@rollbar/react';
import React from 'react';

import { rollbarConfig } from '../rollbar/config';

export default function RollbarProvider({ children }: React.PropsWithChildren) {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </Provider>
  );
}
