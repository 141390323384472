'use client';

import { useContext } from 'react';

import { LanguageContext } from '../providers/LanguageProvider';

// Custom hook that shorthands the LanguageContext.
const useAppLanguage = () => useContext(LanguageContext);

export default useAppLanguage;
