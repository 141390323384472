'use client';

import { Callback } from 'i18next';
import React, { createContext, useEffect, useState } from 'react';
import { I18nextProvider, TFunction, useTranslation } from 'react-i18next';

import i18n from '../i18n';
import { AppLanguage, AppLanguages, DEFAULT_LANGUAGE, languages } from '../types/language';

interface LanguageContextProps {
  languages: AppLanguages;
  currentLanguage: AppLanguage;
  changeLanguage: (lng?: string | undefined, callback?: Callback | undefined) => Promise<TFunction>;
  translate: TFunction;
}

export const LanguageContext = createContext<LanguageContextProps>({} as LanguageContextProps);

export default function LanguageProvider({ children }: React.PropsWithChildren) {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  const currentLanguage = languages[i18n.resolvedLanguage as keyof typeof languages] ?? languages[DEFAULT_LANGUAGE];

  useEffect(() => {
    setLoaded(true);
  }, [setLoaded]);

  if (!loaded) {
    return null;
  }

  return (
    <LanguageContext.Provider
      value={{
        languages: languages,
        currentLanguage,
        changeLanguage: i18n.changeLanguage,
        translate: t,
      }}
    >
      <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
    </LanguageContext.Provider>
  );
}
