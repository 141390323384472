import React from 'react';

import { LogoMark, LogoWord } from './Logo';

const LeftLogoV2: React.FC = () => {
  return (
    <div className="flex h-7">
      <LogoMark
        // small
        display={{
          base: 'block',
          sm: 'none',
        }}
      />
      <LogoWord
        // big
        display={{
          base: 'none',
          sm: 'block',
        }}
      />
    </div>
  );
};

export default LeftLogoV2;
