export enum US_STATE_ENUM_VALS {
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FL = 'FL',
  GA = 'GA',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

// Define constant with array of all US states in {label, value} format.
const US_STATES = [
  { value: US_STATE_ENUM_VALS.AL, label: 'Alabama' },
  { value: US_STATE_ENUM_VALS.AK, label: 'Alaska' },
  { value: US_STATE_ENUM_VALS.AZ, label: 'Arizona' },
  { value: US_STATE_ENUM_VALS.AR, label: 'Arkansas' },
  { value: US_STATE_ENUM_VALS.CA, label: 'California' },
  { value: US_STATE_ENUM_VALS.CO, label: 'Colorado' },
  { value: US_STATE_ENUM_VALS.CT, label: 'Connecticut' },
  { value: US_STATE_ENUM_VALS.DE, label: 'Delaware' },
  { value: US_STATE_ENUM_VALS.DC, label: 'District Of Columbia' },
  { value: US_STATE_ENUM_VALS.FL, label: 'Florida' },
  { value: US_STATE_ENUM_VALS.GA, label: 'Georgia' },
  { value: US_STATE_ENUM_VALS.HI, label: 'Hawaii' },
  { value: US_STATE_ENUM_VALS.ID, label: 'Idaho' },
  { value: US_STATE_ENUM_VALS.IL, label: 'Illinois' },
  { value: US_STATE_ENUM_VALS.IN, label: 'Indiana' },
  { value: US_STATE_ENUM_VALS.IA, label: 'Iowa' },
  { value: US_STATE_ENUM_VALS.KS, label: 'Kansas' },
  { value: US_STATE_ENUM_VALS.KY, label: 'Kentucky' },
  { value: US_STATE_ENUM_VALS.LA, label: 'Louisiana' },
  { value: US_STATE_ENUM_VALS.ME, label: 'Maine' },
  { value: US_STATE_ENUM_VALS.MD, label: 'Maryland' },
  { value: US_STATE_ENUM_VALS.MA, label: 'Massachusetts' },
  { value: US_STATE_ENUM_VALS.MI, label: 'Michigan' },
  { value: US_STATE_ENUM_VALS.MN, label: 'Minnesota' },
  { value: US_STATE_ENUM_VALS.MS, label: 'Mississippi' },
  { value: US_STATE_ENUM_VALS.MO, label: 'Missouri' },
  { value: US_STATE_ENUM_VALS.MT, label: 'Montana' },
  { value: US_STATE_ENUM_VALS.NE, label: 'Nebraska' },
  { value: US_STATE_ENUM_VALS.NV, label: 'Nevada' },
  { value: US_STATE_ENUM_VALS.NH, label: 'New Hampshire' },
  { value: US_STATE_ENUM_VALS.NJ, label: 'New Jersey' },
  { value: US_STATE_ENUM_VALS.NM, label: 'New Mexico' },
  { value: US_STATE_ENUM_VALS.NY, label: 'New York' },
  { value: US_STATE_ENUM_VALS.NC, label: 'North Carolina' },
  { value: US_STATE_ENUM_VALS.ND, label: 'North Dakota' },
  { value: US_STATE_ENUM_VALS.OH, label: 'Ohio' },
  { value: US_STATE_ENUM_VALS.OK, label: 'Oklahoma' },
  { value: US_STATE_ENUM_VALS.OR, label: 'Oregon' },
  { value: US_STATE_ENUM_VALS.PA, label: 'Pennsylvania' },
  { value: US_STATE_ENUM_VALS.RI, label: 'Rhode Island' },
  { value: US_STATE_ENUM_VALS.SC, label: 'South Carolina' },
  { value: US_STATE_ENUM_VALS.SD, label: 'South Dakota' },
  { value: US_STATE_ENUM_VALS.TN, label: 'Tennessee' },
  { value: US_STATE_ENUM_VALS.TX, label: 'Texas' },
  { value: US_STATE_ENUM_VALS.UT, label: 'Utah' },
  { value: US_STATE_ENUM_VALS.VT, label: 'Vermont' },
  { value: US_STATE_ENUM_VALS.VA, label: 'Virginia' },
  { value: US_STATE_ENUM_VALS.WA, label: 'Washington' },
  { value: US_STATE_ENUM_VALS.WV, label: 'West Virginia' },
  { value: US_STATE_ENUM_VALS.WI, label: 'Wisconsin' },
  { value: US_STATE_ENUM_VALS.WY, label: 'Wyoming' },
];

export default US_STATES;
