import { CCMS_PROGRAM_TYPE_ENUMS } from '../../constants/programs';
import { TIMEZONE_ENUMS } from '../../constants/timezones';
import { US_STATE_ENUM_VALS } from '../../constants/us-states';
import { BaseFirebaseDocument } from '../../types/firebase';

// this is generated within sso
export interface SsoSignup extends BaseFirebaseDocument {
  userId: string;
  email: string;
  firstName: string;
  lastName: string;
  phone?: string;
  lang: string;
  networkId?: string;
  newSupply?: boolean;
}

export interface OnboardingFormSchema {
  [FORM_FIELD_NAMES.PROGRAM_STATUS]: PROGRAM_STATUS_ENUMS | null;
  [FORM_FIELD_NAMES.NAME]: string;
  [FORM_FIELD_NAMES.ADDRESS_1]: string;
  [FORM_FIELD_NAMES.ADDRESS_2]: string | null;
  [FORM_FIELD_NAMES.CITY]: string;
  [FORM_FIELD_NAMES.STATE]: US_STATE_ENUM_VALS;
  [FORM_FIELD_NAMES.ZIPCODE]: string;
  [FORM_FIELD_NAMES.PROGRAM_TYPE]: CCMS_PROGRAM_TYPE_ENUMS;
  [FORM_FIELD_NAMES.LICENSE_CAPACITY]: number;
  [FORM_FIELD_NAMES.NETWORK_CODE]: string | null;
  [FORM_FIELD_NAMES.TIMEZONE]: TIMEZONE_ENUMS;
  [FORM_FIELD_NAMES.LATITUDE]: number;
  [FORM_FIELD_NAMES.LONGITUDE]: number;
  [FORM_FIELD_NAMES.EMAIL]: string;
  [FORM_FIELD_NAMES.PHONE]?: string;
  [FORM_FIELD_NAMES.FIRST_NAME]: string;
  [FORM_FIELD_NAMES.LAST_NAME]: string;
  [FORM_FIELD_NAMES.NEW_SUPPLY]: boolean;
}

export enum PROGRAM_STATUS_ENUMS {
  EXISTING = 'EXISTING_PROGRAM',
  NEW = 'NEW_PROGRAM',
}
export enum USER_TYPE_ENUMS {
  PROVIDER = 'provider',
  FAMILY = 'family',
}

export const PROGRAM_STATUS_OPTIONS = [
  {
    label: 'I am an existing provider',
    value: PROGRAM_STATUS_ENUMS.EXISTING,
  },
  {
    label: 'I want to start a new program',
    value: PROGRAM_STATUS_ENUMS.NEW,
  },
];

export enum FORM_FIELD_NAMES {
  PROGRAM_STATUS = 'programStatus',
  NAME = 'name',
  ADDRESS_1 = 'address1',
  ADDRESS_2 = 'address2',
  CITY = 'city',
  STATE = 'state',
  ZIPCODE = 'zipcode',
  PROGRAM_TYPE = 'programType',
  PHONE = 'phone',
  LICENSE_CAPACITY = 'licenseCapacity',
  NETWORK_CODE = 'networkCode',
  TIMEZONE = 'timezone',
  GEOMETRY = 'geometry',
  LATITUDE = 'lat',
  LONGITUDE = 'lng',
  EMAIL = 'email',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  NEW_SUPPLY = 'newSupply',
  USER_TYPE = 'userType',
}

export interface EmailFormSchema {
  [FORM_FIELD_NAMES.EMAIL]: string;
  [FORM_FIELD_NAMES.USER_TYPE]: USER_TYPE_ENUMS;
}

export enum ANON_USER_KEYS {
  TYPE = 'type',
  EMAIL = 'email',
  NEW_SUPPLY = 'newSupply',
}

// TODO: CHANGE THIS TO STANDARD ROUTE WHEN APPROVED!
export enum SSO_ROUTES {
  SIGNUP = 'signup',
  INVITATION = 'invitation',
}
