import moment from 'moment';

import { FORM_FIELD_NAMES } from '../components/Onboarding/types';
import { TIMEZONES } from '../constants/timezones';
import { US_STATE_ENUM_VALS } from '../constants/us-states';
import { FormattedPlace } from '../types/location';
import fetchTimezoneApi from './fetch-timezone-api';

type JWT = string;
type TokenParseResult = JWT | null;

export function getJwtFromToken(token: string | null): TokenParseResult {
  return token?.replace(/^Bearer\s+/, '') ?? null;
}

export function decodeJwt(jwt: JWT) {
  return jwt;
}

export async function formatLocationPlaceObject(place: google.maps.places.PlaceResult): Promise<FormattedPlace> {
  let stNumber;
  let stName;
  let city;
  let state: US_STATE_ENUM_VALS = US_STATE_ENUM_VALS.AK;
  let zipcode;
  const geometry = {
    lat: place?.geometry?.location?.lat() ?? 0,
    lng: place?.geometry?.location?.lng() ?? 0,
  };
  place?.address_components?.forEach((address_comp) => {
    address_comp.types.forEach((type) => {
      if (type === 'street_number') {
        stNumber = address_comp.long_name;
      }

      if (type === 'route') {
        stName = address_comp.long_name;
      }

      if (type === 'locality') {
        city = address_comp.long_name;
      }

      if (type === 'administrative_area_level_1') {
        state = address_comp.short_name as US_STATE_ENUM_VALS;
      }

      if (type === 'postal_code') {
        zipcode = address_comp.long_name;
      }
    });
  });

  const googleTzApiResponse = await fetchTimezoneApi({
    lat: geometry.lat,
    long: geometry.lng,
  });

  const reshaped = {
    formatted: {
      [FORM_FIELD_NAMES.ADDRESS_1]: `${stNumber ?? ''} ${stName}`,
      [FORM_FIELD_NAMES.CITY]: city ?? '',
      [FORM_FIELD_NAMES.STATE]: state ?? '',
      [FORM_FIELD_NAMES.ZIPCODE]: zipcode ?? '',
      [FORM_FIELD_NAMES.TIMEZONE]:
        TIMEZONES.find((tz) => tz.value === googleTzApiResponse?.timeZoneId)?.value ?? TIMEZONES[0].value,
      [FORM_FIELD_NAMES.GEOMETRY]: geometry,
    },
    raw: place,
  };

  return reshaped;
}

export function stripTrailingSlash(text?: string) {
  if (!text) {
    return '';
  }
  return text.replace(/\/+$/, '');
}

export function getTimezoneOptions() {
  const isDst = moment().isDST();

  return TIMEZONES.map((tz) => {
    const tagObj = isDst ? tz.dstTag : tz.tag;
    return {
      value: tz.value,
      label: `${tz.label} (${tagObj.abbr}, UTC${tagObj.utc})`,
    };
  });
}

export function checkHasBrandingData(brandingData?: unknown): boolean {
  const brandingDataKeys = Object.keys(brandingData ?? {});
  return (
    brandingDataKeys.length > 1 || (brandingDataKeys.length === 1 && brandingDataKeys[0] !== 'networkAutoMigrateCcms')
  );
}
