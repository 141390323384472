export enum TIMEZONE_ENUMS {
  NEW_YORK = 'America/New_York',
  CHICAGO = 'America/Chicago',
  DENVER = 'America/Denver',
  PHOENIX = 'America/Phoenix',
  LOS_ANGELES = 'America/Los_Angeles',
  ANCHORAGE = 'America/Anchorage',
  HONOLULU = 'Pacific/Honolulu',
}

export type TimezoneOption = {
  value: TIMEZONE_ENUMS;
  label: TIMEZONE_ENUMS;
  tag: { abbr: string; utc: string };
  dstTag: { abbr: string; utc: string };
};

export const TIMEZONES: TimezoneOption[] = [
  {
    value: TIMEZONE_ENUMS.NEW_YORK,
    label: TIMEZONE_ENUMS.NEW_YORK,
    tag: { abbr: 'EST', utc: '-05:00' },
    dstTag: { abbr: 'EDT', utc: '-04:00' },
  },
  {
    value: TIMEZONE_ENUMS.CHICAGO,
    label: TIMEZONE_ENUMS.CHICAGO,
    tag: { abbr: 'CST', utc: '-06:00' },
    dstTag: { abbr: 'CDT', utc: '-05:00' },
  },
  {
    value: TIMEZONE_ENUMS.DENVER,
    label: TIMEZONE_ENUMS.DENVER,
    tag: { abbr: 'MST', utc: '-07:00' },
    dstTag: { abbr: 'MDT', utc: '-06:00' },
  },
  {
    value: TIMEZONE_ENUMS.PHOENIX,
    label: TIMEZONE_ENUMS.PHOENIX,
    tag: { abbr: 'MST', utc: '-07:00' },
    dstTag: { abbr: 'MST', utc: '-07:00' },
  },
  {
    value: TIMEZONE_ENUMS.LOS_ANGELES,
    label: TIMEZONE_ENUMS.LOS_ANGELES,
    tag: { abbr: 'PST', utc: '-08:00' },
    dstTag: { abbr: 'PDT', utc: '-07:00' },
  },
  {
    value: TIMEZONE_ENUMS.ANCHORAGE,
    label: TIMEZONE_ENUMS.ANCHORAGE,
    tag: { abbr: 'AKST', utc: '-09:00' },
    dstTag: { abbr: 'AKDT', utc: '-08:00' },
  },
  {
    value: TIMEZONE_ENUMS.HONOLULU,
    label: TIMEZONE_ENUMS.HONOLULU,
    tag: { abbr: 'HST', utc: '-10:00' },
    dstTag: { abbr: 'HST', utc: '-10:00' },
  },
];
