'use client';

import type { ProviderConfig } from 'launchdarkly-react-client-sdk';

const clientSideID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID ?? '';

export const makeLDContext = (): ProviderConfig => {
  return {
    clientSideID,
    user: {
      anonymous: true,
    },
    options: {
      bootstrap: 'localStorage',
    },
  };
};
